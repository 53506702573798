export const BASEURL = window.location.href

export const GAODEMAP = {
	key: 'ef356d4518d845e6d26bf35fc902a296',
	url: 'https://m.amap.com/navi/'
}

export const AGENTID = 690;
export const IconUrlFrom365 = 'http://oss.365taoquan.cn/commodityTypeLogo'
export const TaoJuan365BaseUrl = 'https://tq.jfshou.cn/seller/app/classify?';
export const SECRETKEY_365TAOJUAN = 'bMatjJP4rN4B7xCrnk6jryeWwH4p68xj'
export const CZB = {
	plateformType: 98643999,
	appKey: 'shangzhutong1.0',
	appSecret: '9136559113107eab9e4cbb0312bdbf08',
	domain: 'https://mcs.czb365.com/services/v3/',
	payUrl: 'https://open.czb365.com/redirection/todo',
}

export const ASCIISORTKEY_SELF = '1hehe^_^haha!ysyhl9t@flzx3000c'

export const ASCIISORTKEY_SELF_MINIAPP = '1hehe^_^haha!ysyhl9t@flzx3000c'

export const USER_AGENT = {
	IOS: {
		K: "yy_iOS",
		V: 0
	},
	ANDROID: {
		K: 'yy_Android',
		V: 1
	},
	OTHERS: {
		K: '',
		V: 2
	}
}
