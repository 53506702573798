const list = () => import("@/views/list/list.vue");
const listDetails = () => import("@/views/list/listDetails.vue");

export default [
	{
		path: '/list', // 榜单
		name: 'list',
		component: list,
		meta: {
			title: '',
			keepAlive: false
		}
	},
	{
		path: '/listDetails', // 活动说明
		name: 'listDetails',
		component: listDetails,
		meta: {
			title: '',
			keepAlive: false
		}
	},
]