// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const ko = {
	lang: {
		// login页面
		language: '언어',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		cancel: '취소',
		AJtitle: '보안인증을 완료해주세요',
		AJsuccess: '확인 성공',
		AJfailed: '확인 실패',
		hintTitle: '힌트:',
		hintText: '인증번호를 받지 못하신 경우, 스팸메일을 확인하시고, 이메일 주소가 올바른지 확인해 주시기 바랍니다. 또한 메일@m.whistle3.com을 화이트리스트에 추가해 주세요.',
		SignUp: '가입',
		SignUpTitle: '새 계정 만들기',
		email: '가입 이메일을 입력하세요',
		email_code: '이메일 인증 코드를 입력하세요',
		getCode: '인증 코드 받기',
		recomId: '초대코드를 입력하세요',
		pwd: '로그인 비밀번호를 설정하세요',
		pwdIn: '로그인 비밀번호를 다시 입력하세요',
		loginBut: '가입',
		check: '동의함',
		userAgree: '《사용자 동의서》',
		and: '및',
		privacyPolicy: '《개인 정보 보호 정책》',
		download: '다운로드하러 가기',
		loginToast1: '동의를 선택해주세요',
		loginToast2: '유효한 이메일을 입력하세요',
		loginToast3: '먼저 이메일을 입력하세요',
		loginToast4: '먼저 인증 코드를 입력하세요',
		loginToast5: '먼저 비밀번호를 입력하세요',
		loginToast6: '비밀번호는 최소 8자 이상이어야 합니다',
		loginToast7: '먼저 확인 비밀번호를 입력하세요',
		loginToast8: '두 번 입력한 비밀번호가 일치하지 않습니다',
		loginToast9: '가입이 성공적으로 완료되었습니다',
		loginToast10: '',
	},
	// ...zhCN,
	// ...zhLocale
}

export default ko
