// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const vi = {
	lang: {
		// login页面
		language: 'Ngôn ngữ',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		cancel: 'Hủy bỏ',
		AJtitle: 'Vui lòng hoàn thành xác minh an toàn',
		AJsuccess: 'Xác minh thành công',
		AJfailed: 'Xác minh thất bại',
		hintTitle: 'Gợi ý:',
		hintText: 'Nếu bạn không nhận được mã xác minh, vui lòng kiểm tra hộp thư rác của bạn và đảm bảo địa chỉ email là chính xác. Đồng thời, thêm mail@m.whistle3.com vào danh sách trắng.',
		SignUp: 'Đăng ký',
		SignUpTitle: 'Tạo tài khoản mới',
		email: 'Nhập địa chỉ email để đăng ký',
		email_code: 'Nhập mã xác minh qua email',
		getCode: 'Nhận mã',
		recomId: 'Nhập mã mời',
		pwd: 'Vui lòng đặt mật khẩu đăng nhập',
		pwdIn: 'Nhập lại mật khẩu đăng nhập',
		loginBut: 'Đăng ký',
		check: 'Đã đọc và đồng ý với',
		userAgree: '《Thỏa thuận người dùng》',
		and: 'và',
		privacyPolicy: '《Chính sách bảo mật》',
		download: 'Tải về',
		loginToast1: 'Vui lòng chấp nhận thỏa thuận',
		loginToast2: 'Vui lòng nhập địa chỉ email hợp lệ',
		loginToast3: 'Vui lòng nhập email trước',
		loginToast4: 'Vui lòng nhập mã xác minh trước',
		loginToast5: 'Vui lòng nhập mật khẩu trước',
		loginToast6: 'Mật khẩu ít nhất 8 ký tự',
		loginToast7: 'Vui lòng nhập lại mật khẩu',
		loginToast8: 'Hai lần nhập khẩu không khớp',
		loginToast9: 'Đăng ký thành công',
		loginToast10: '',
	},
	// ...zhCN,
	// ...zhLocale
}

export default vi
