import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import { Locale } from 'vant'
import en_US from 'vant/lib/locale/lang/en-US'
import zh_CN from 'vant/lib/locale/lang/zh-CN'
import messages from './langs'

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: localStorage.getItem('lang') || 'en_US',
	messages,
	// 隐藏警告
	silentTranslationWarn: true
})
// vant 多语言切换
let lang = localStorage.getItem('lang') || 'en_US'
if (lang === 'en_US') {
	Locale.use(lang, 'en_US')
} else if (lang === 'zh_CN') {
	Locale.use(lang, 'zh_CN')
} else if (lang === 'ja_JP') {
	Locale.use(lang, 'ja_JP')
} else if (lang === 'ko_KR') {
	Locale.use(lang, 'ko_KR')
} else if (lang === 'ru_RU') {
	Locale.use(lang, 'ru_RU')
} else if (lang === 'vi_VN') {
	Locale.use(lang, 'vi_VN')
}

locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换
console.log(localStorage.getItem('lang'))
export default i18n