import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 初始化路由动态加载
let moduleRouters = []
const context = require.context('./modules', false, /.js$/)
context.keys().forEach(key => {
  moduleRouters = moduleRouters.concat(context(key).default)
})

const routes = [
  ...moduleRouters,
  {
    path: '/',
    name: 'download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/download/download.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
