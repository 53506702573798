const JPexplain = () => import("@/views/explain/JPexplain.vue");
const QGexplain = () => import("@/views/explain/QGexplain.vue");
const DJCexplain = () => import("@/views/explain/DJCexplain.vue");
const identityUp = () => import("@/views/explain/identityUp.vue");
const vipUpExplain = () => import("@/views/explain/vipUpExplain.vue");

export default [
	{
		path: '/JPexplain', // 竞拍规则
		name: 'JPexplain',
		component: JPexplain,
		meta: {
			title: '竞拍规则',
			keepAlive: false
		}
	},
	{
		path: '/QGexplain', // 抢购规则
		name: 'QGexplain',
		component: QGexplain,
		meta: {
			title: '抢购规则',
			keepAlive: false
		}
	},
	{
		path: '/DJCexplain', // 镀金池规则
		name: 'DJCexplain',
		component: DJCexplain,
		meta: {
			title: '镀金池规则',
			keepAlive: false
		}
	},
	{
		path: '/identityUp', // 身份升级
		name: 'identityUp',
		component: identityUp,
		meta: {
			title: '身份升级',
			keepAlive: false
		}
	},
	{
		path: '/vipUpExplain', // 会员升级说明
		name: 'vipUpExplain',
		component: vipUpExplain,
		meta: {
			title: '会员升级说明',
			keepAlive: false
		}
	},
]