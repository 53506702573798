const zoology = () => import("@/views/zoology/zoology.vue");

export default [
	{
		path: '/zoology', // whistle生态
		name: 'zoology',
		component: zoology,
		meta: {
			title: 'Whistle生态',
			keepAlive: false
		}
	},
]