const linkApp = () => import("@/views/linkApp/linkApp.vue");

export default [
	{
		path: '/linkApp', // 打开APP
		name: 'linkApp',
		component: linkApp,
		meta: {
			title: '打开APP',
			keepAlive: false
		}
	},
]