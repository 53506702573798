import HB from './bridge';
import {BASEURL,SECRETKEY_365TAOJUAN,TaoJuan365BaseUrl,AGENTID,ASCIISORTKEY_SELF} from './constant';
import md5 from "js-md5";
import {Indicator,Toast,MessageBox} from "mint-ui";
import axios from "axios";
import wx from "weixin-js-sdk";
import CryptoJS from 'crypto-js';

// 头部sec 值为 md5(约定安全字符+日期格式字符串（yyyyMMdd）+手机号码)
export const getMsgCode = (d, key) => {
	const data = Object.assign({
		smsType: 1,
		appFlag: 9
	}, d)
	let sec = key + getDate(new Date()).slice(0, 10).replace(/-/g, '') + d.phone;
	const service = axios.create({
		headers: {
			sec: md5(sec),
			"content-type": "application/x-www-form-urlencoded",
			userAgent: "zst-android-broswer",
			miniProgram: "ios" //新增的判断为安卓还是ios
		},
		timeout: 100000 // 请求超时时间
	});
	let formData = new FormData();
	Object.entries(data).forEach(([key, val]) => {
		formData.append(key, val)
	})
	return new Promise((resolve, reject) => {
		service({
			url: "/api/huibo/v20190304/smsSecurityCode",
			method: "post",
			data: formData
		}).then(res => {
			if (res.data.state == 1) {
				resolve(res.data)
			} else {
				Indicator.close()
				MessageBox.alert(decodeURIComponent(res.data.msg))
			}
		})
	})
}

const asciiSort = (json, key) => {
	let arr = [],
		_json = {},
		str = "",
		reg = /\,/;
	for (i in json) {
		if (json[i] !== "" && json[i] !== undefined) {
			arr.push(i);
		}
	}
	arr.sort();
	for (var i = 0; i < arr.length; i++) {
		var name = arr[i];
		str += name + "=" + json[name] + "&";
	}
	_json = str + "key=" + key;
	return _json;
}
// 头部sec 值为 md5(约定安全字符+日期格式字符串（yyyyMMdd）+手机号码)
export const bankNoQuery = (d, key) => {
	// let sec = key + getDate(new Date()).slice(0 ,10).replace(/-/g,'') + d.phone;
	const service = axios.create({
		headers: {
			sec: md5(asciiSort(d, key)),
			"content-type": "application/x-www-form-urlencoded",
			userAgent: "zst-android-broswer",
			miniProgram: "ios" //新增的判断为安卓还是ios
		},
		timeout: 100000 // 请求超时时间
	});
	return new Promise((resolve, reject) => {
		service({
			url: "/api/huibo/unionPay/bankNo/query",
			method: "post",
			data: d
		}).then(res => {
			if (res.data.state == 1) {
				resolve(res.data)
			} else {
				Indicator.close()
				MessageBox.alert(decodeURIComponent(res.data.msg))
			}
		})
	})
}

export const autoRecognition = (secData, data) => {
	const fromDate = (s) => {
		return [s.substr(0, 4), s.substr(4, 2), s.substr(6, 2)].join(
			"-"
		);
	};
	const service = axios.create({
		headers: {
			sec: md5(objectToKeyVal(secData)),
			"content-type": "application/x-www-form-urlencoded",
			userAgent: " zst-android-broswer",
			miniProgram: "ios" //新增的判断为安卓还是ios
		},
		timeout: 100000 // 请求超时时间
	});
	let formData = new FormData();
	formData.append("type", data.type);
	formData.append("file", data.file);
	return new Promise(function(resolve, reject) {
		service({
			url: "/api/huibo/certificates/recognise",
			method: "post",
			params: formData,
			data: formData
		}).then(res => {

			let result = res.data.data;
			result.url = res.data.url
			for (let key in result) {
				if (result[key] == "无") {
					result[key] = "";
				}
			}
			let {
				endDate,
				startDate,
				birthday
			} = result;
			if (startDate) {
				result.startDate = fromDate(startDate);
			}
			if (birthday) {
				result.birthday = fromDate(birthday);
			}
			if (endDate && endDate != "长期") {
				result.endDate = fromDate(endDate);
			}
			resolve(result)
		}).catch(err => {
			reject(err)
		})
	})
}

/*
		获取url参数
	*/
export const getQueryString = (param) => {
	const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
	const r = window.location.search.substr(1).match(reg) || window.location.hash.substring((window.location.hash
		.search(/\?/)) + 1).match(reg);
	if (r != null) {
		return decodeURIComponent(r[2]);
	}
	return null;
}

/**
 * AES加密
 * @param {Object} word 待加密的数据
 * @param {Object} keyStr 密钥
 */
export const encrypt = (word) => {
	var key = CryptoJS.enc.Utf8.parse("flash.art.number");
	var srcs = CryptoJS.enc.Utf8.parse(word);
	var encrypted = CryptoJS.AES.encrypt(srcs, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	return encrypted.toString();
}

/** * AES解密
 * @param {Object} word 待解密的数据
 * @param {Object} keyStr    密钥
 */
export const decrypt = (word) => {
	var key = CryptoJS.enc.Utf8.parse("flash.art.number");
	var decrypt = CryptoJS.AES.decrypt(word, key, {
		mode: CryptoJS.mode.ECB,
		padding: CryptoJS.pad.Pkcs7
	});
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

/**
 * deep clone
 */
export const clone = (data) => {
	return JSON.parse(JSON.stringify(data));
}

export const getAddressById = id => {
	id = id.toString()
	let p = id.slice(0, 2)
	let c = id.slice(2, 4)
	let cc = id.slice(4, 6)
	var str = ''
	str += CITY['100000'][p + '0000']
	str += CITY[p + '0000'][p + c + '00']
	str += CITY[p + c + '00'][p + c + cc]
	return str
}

/**
 * 跳转页面
 * @param {*} data
 * @param {*} that
 *
 */
export const navigate = (data, that, isNotReload = 0) => {
	if (HB.isIos || HB.isAndroid) {
		let url = BASEURL + data.path;
		if (data.query) {
			url = url + '?' + objectToKeyVal(data.query)
		}
		HB.openWeb(url, isNotReload)
	} else {
		that.$router.push(data)
	}
}

/**
 * 获取时间
 * yyyy-mm-dd hh:mm:ss
 */
export const getTime = (date) => {
	const n = date ? new Date(date) : new Date();
	let [year, month, day, hh, mm, ss] = [
		n.getFullYear(),
		parseInt(n.getMonth() + 1),
		parseInt(n.getDate()),
		parseInt(n.getHours()),
		parseInt(n.getMinutes()),
		parseInt(n.getSeconds())
	]

	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : '0' + day;
	hh = hh > 9 ? hh : '0' + hh;
	mm = mm > 9 ? mm : '0' + mm;
	ss = ss > 9 ? ss : '0' + ss;
	return [year, month, day].join("-") + " " + [hh, mm, ss].join(":");
}

/**
 * 获取时分秒
 * yyyy-mm-dd hh:mm:ss
 */
export const getHMS = (date) => {
	const n = date ? new Date(date) : new Date();
	let [year, month, day, hh, mm, ss] = [
		n.getFullYear(),
		parseInt(n.getMonth() + 1),
		parseInt(n.getDate()),
		parseInt(n.getHours()),
		parseInt(n.getMinutes()),
		parseInt(n.getSeconds())
	]
	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : '0' + day;
	hh = hh > 9 ? hh : '0' + hh;
	mm = mm > 9 ? mm : '0' + mm;
	ss = ss > 9 ? ss : '0' + ss;
	return [hh, mm, ss].join(":");
}
/**
 * 获取时分
 * hh:mm
 */
export const getHM = (date) => {
	const n = date ? new Date(date) : new Date();
	let [year, month, day, hh, mm, ss] = [
		n.getFullYear(),
		parseInt(n.getMonth() + 1),
		parseInt(n.getDate()),
		parseInt(n.getHours()),
		parseInt(n.getMinutes()),
		parseInt(n.getSeconds())
	]

	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : '0' + day;
	hh = hh > 9 ? hh : '0' + hh;
	mm = mm > 9 ? mm : '0' + mm;
	ss = ss > 9 ? ss : '0' + ss;
	return [hh, mm].join(":");
}
/**
 * 获取日期
 * yyyy-mm-dd
 */
export const getDate = (date) => {
	const n = date ? new Date(date) : new Date();
	let [year, month, day, hh, mm, ss] = [
		n.getFullYear(),
		parseInt(n.getMonth() + 1),
		parseInt(n.getDate()),
		parseInt(n.getHours()),
		parseInt(n.getMinutes()),
		parseInt(n.getSeconds())
	]

	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : '0' + day;
	hh = hh > 9 ? hh : '0' + hh;
	mm = mm > 9 ? mm : '0' + mm;
	ss = ss > 9 ? ss : '0' + ss;
	return [year, month, day].join("-");
}
/**
 * 获取年月
 * yyyy-mm
 */
export const getYM = (date) => {
	const n = date ? new Date(date) : new Date();
	let [year, month, day, hh, mm, ss] = [
		n.getFullYear(),
		parseInt(n.getMonth() + 1),
		parseInt(n.getDate()),
		parseInt(n.getHours()),
		parseInt(n.getMinutes()),
		parseInt(n.getSeconds())
	]

	month = month > 9 ? month : ('0' + month);
	day = day > 9 ? day : '0' + day;
	hh = hh > 9 ? hh : '0' + hh;
	mm = mm > 9 ? mm : '0' + mm;
	ss = ss > 9 ? ss : '0' + ss;
	return [year, month].join("-");
}
/**
 * 格式化秒
 * 00:00:00
 */
export const getSeconds = (seconds) => {
	// var secondType = typeof second;
	// if (secondType === "number" || secondType === "string") {
	//   second = parseInt(second);
	//   var mimute = Math.floor(second / 60);
	//   second = second - mimute * 60;
	//   return ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
	// } else {
	//   return "00:00";
	// }
	let hour = Math.floor(seconds / 3600) >= 10 ? Math.floor(seconds / 3600) : '0' + Math.floor(seconds / 3600);
	seconds -= 3600 * hour;
	let min = Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : '0' + Math.floor(seconds / 60);
	seconds -= 60 * min;
	let sec = seconds >= 10 ? seconds : '0' + seconds;
	return hour + ':' + min + ':' + sec;
}
/**
 *365 详情
 * **/
export const to365TAOJuanDetail = (json) => {
	json = Object.assign(json, {
		agentId: AGENTID,
		timestamp: Date.now()
	})
	const str = objectToKeyVal(JSON.parse(JSON.stringify(json, Object.keys(json).sort())))
	const _json = str + "&secretKey=" + SECRETKEY_365TAOJUAN;
	json.sign = md5(_json).toLocaleUpperCase();
	HB.openWeb(TaoJuan365BaseUrl + objectToKeyVal(json))
}
export const hasClass = (el, className) => {
	// 开始或空白字符+类名+空白字符或结束
	let reg = new RegExp('(^|\\s)' + className + '(\\s|$)')
	// 测试元素是否有该类名，返回布尔值
	return reg.test(el.className)
}

export const addClass = (el, className) => {
	if (hasClass(el, className)) {
		return
	}
	// 以空白符为切割位置切割生成新数组
	let newClass = el.className.split(' ')
	// 数组中加入新类名
	newClass.push(className)
	// 将数组元素放入一个字符串，以空白符间隔
	el.className = newClass.join(' ')
}
/**
 * 对象转 key=val 键值对
 *
 */
export const objectToKeyVal = (obj) => {
	return Object.entries(obj).map(t => t = t.join("=")).join("&")
}

export const copyText = (text) => {
	var tag = document.createElement('input');
	tag.setAttribute('id', 'cp_hgz_input');
	tag.value = text;
	document.getElementsByTagName('body')[0].appendChild(tag);
	document.getElementById('cp_hgz_input').select();
	document.execCommand('copy');
	document.getElementById('cp_hgz_input').remove();
}

//
export const setNavbarHeight = (className = 'header-wrap', color = '') => {
	if (HB.isIos) {
		const dom = document.getElementsByClassName(className);
		if (dom[0]) {
			let height = 33;
			if (HB.isIphoneX) {
				height += 10
			}
			let str = height + 'px solid ' + color;
			dom[0].style.borderTop = str;
		} else {
			setTimeout(() => {
				setNavbarHeight(className, color)
			}, 100)
		}
	}
}

export const pageVisibilitychange = (cb) => {
	const hiddenProperty =
		"hidden" in document ?
		"hidden" :
		"webkitHidden" in document ?
		"webkitHidden" :
		"mozHidden" in document ?
		"mozHidden" :
		null;
	const visibilityChangeEvent = hiddenProperty.replace(
		/hidden/i,
		"visibilitychange"
	);
	const onVisibilityChange = function() {
		cb(!document[hiddenProperty])
	};
	document.addEventListener(visibilityChangeEvent, onVisibilityChange);
}

export const weiXinSdk = (params = {}, apiList) => {
	return new Promise((resolve, reject) => {
		getWeiXinSdkInfo(params).then(res => {
			const result = res.data;
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: result.appid, // 必填，公众号的唯一标识
				timestamp: result.timestamp, // 必填，生成签名的时间戳
				nonceStr: result.noncestr, // 必填，生成签名的随机串
				signature: result.signature, // 必填，签名
				jsApiList: apiList // 必填，需要使用的JS接口列表
			});
			wx.error(function(res) {
				alert(JSON.stringify(res));
				Indicator.close();
				reject(false, wx)
			});
			wx.ready(() => {
				resolve(true, wx)
			});
		})
	})
}
/**
 * [ShareTimeline 微信分享到朋友圈]
 * @param {[type]} option [分享信息]
 * @param {[type]} success [成功回调]
 * @param {[type]} error   [失败回调]
 */
export const ShareTimeline = (option) => {
	wx.onMenuShareTimeline({
		title: option.title, // 分享标题
		link: option.link, // 分享链接
		imgUrl: option.imgUrl, // 分享图标
		success() {
			// 用户成功分享后执行的回调函数
			option.success()
		},
		cancel() {
			// 用户取消分享后执行的回调函数
			option.error()
		}
	})
}
/**
 * [ShareAppMessage 微信分享给朋友]
 * @param {[type]} option [分享信息]
 * @param {[type]} success [成功回调]
 * @param {[type]} error   [失败回调]
 */
export const ShareAppMessage = (option) => {
	wx.onMenuShareAppMessage({
		title: option.title, // 分享标题
		desc: option.desc, // 分享描述
		link: option.link, // 分享链接
		imgUrl: option.imgUrl, // 分享图标
		success() {
			// 用户成功分享后执行的回调函数
			option.success()
		},
		cancel() {
			// 用户取消分享后执行的回调函数
			option.error()
		}
	})
}
export const accSub = (arg1, arg2) => {
	if (arg1 == null) {
		arg1 = 0
	}
	if (arg2 == null) {
		arg2 = 0
	}
	var r1, r2, m, n;
	try {
		r1 = arg1.toString().split(".")[1].length
	} catch (e) {
		r1 = 0
	}
	try {
		r2 = arg2.toString().split(".")[1].length
	} catch (e) {
		r2 = 0
	}
	m = Math.pow(10, Math.max(r1, r2));
	n = (r1 >= r2) ? r1 : r2;
	return ((arg2 * m - arg1 * m) / m).toFixed(2);
}
//乘
export const accMul = (arg1, arg2) => {
	if (arg1 == null) {
		arg1 = 0
	}
	if (arg2 == null) {
		arg2 = 0
	}
	var m = 0,
		s1 = arg1.toString(),
		s2 = arg2.toString();
	try {
		m += s1.split(".")[1].length
	} catch (e) {}
	try {
		m += s2.split(".")[1].length
	} catch (e) {}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m).toFixed(2)
}
//除
export const accDiv = (arg1, arg2) => {
	arg1 = parseFloat(arg1);
	arg2 = parseFloat(arg2);
	var t1 = 0,
		t2 = 0,
		r1, r2;
	try {
		t1 = arg1.toString().split(".")[1].length;
	} catch (e) {}
	try {
		t2 = arg2.toString().split(".")[1].length;
	} catch (e) {}
	r1 = Number(arg1.toString().replace(".", ""));
	r2 = Number(arg2.toString().replace(".", ""));
	return accMul(r1 / r2, Math.pow(10, t2 - t1));
}
