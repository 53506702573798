const login = () => import("@/views/login/login.vue");

export default [
	{
		path: '/login', // 注册
		name: 'login',
		component: login,
		meta: {
			title: '注册',
			keepAlive: false
		}
	},
]