const form = () => import("@/views/form/form.vue");

export default [
	{
		path: '/form', // 注册
		name: 'form',
		component: form,
		meta: {
			title: '',
			keepAlive: false
		}
	},
]