(function(doc, win) {
	let docEl = doc.documentElement
	let resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize'
	let recalc = function() {
		var clientWidth = docEl.clientWidth
		if (clientWidth) {
			// html.fontSize = document.width / 设计图rem值 + px
			docEl.style.fontSize = (clientWidth / 750) * 100 + 'px'
		}
	}
	if (doc.addEventListener) {
		win.addEventListener(resizeEvt, recalc, false)
		doc.addEventListener('DOMContentLoaded', recalc, false)
	}
})(document, window)
