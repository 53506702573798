import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
	lang: {
		// login页面
		language: '语言',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		cancel: '取消',
		AJtitle: '请完成安全验证',
		AJsuccess: '验证成功',
		AJfailed: '验证失败',
		hintTitle: '提示：',
		hintText: '如果您没有收到验证码，请检查您的邮箱垃圾箱，并确保邮件地址正确。同时，请将 mail@m.whistle3.com 添加到白名单。',
		SignUp: '注册',
		SignUpTitle: '创建新帐户',
		email: '请输入注册邮箱',
		email_code: '请输入邮箱验证码',
		getCode: '获取验证码',
		recomId: '请输入邀请码',
		pwd: '请设置登录密码',
		pwdIn: '请再次输入登录密码',
		loginBut: '注册',
		check: '已阅读并同意',
		userAgree: '《用户协议》',
		and: '和',
		privacyPolicy: '《隐私政策》',
		download: '去下载',
		loginToast1: '请选择同意协议',
		loginToast2: '请输入有效的邮箱',
		loginToast3: '请先输入邮箱',
		loginToast4: '请先输入验证码',
		loginToast5: '请先输入密码',
		loginToast6: '密码至少8位',
		loginToast7: '请先输入确认密码',
		loginToast8: '两次密码不一致',
		loginToast9: '注册成功',
		loginToast10: '',
	},
	...zhCN,
	...zhLocale
}

export default zh
