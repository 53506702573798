export default {
  install (Vue) {
    // 防止重复点击
    Vue.directive('preventReClick', {
      inserted (el, binding) {
          // console.log("binding-7",binding)
          // console.log("binding-8",el)
        el.addEventListener('click', () => {
          if (el.style.pointerEvents !== "none") {
            el.style.pointerEvents = "none"
            setTimeout(() => {
              el.style.pointerEvents = ""
            },binding.value || 3000)
          }
        })
      }
    })
  }
}
