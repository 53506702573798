// import zhCN from 'vant/lib/locale/lang/zh-CN'
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const ru = {
	lang: {
		// login页面
		language: 'Язык',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		cancel: 'Отмена',
		AJtitle: 'Пожалуйста, выполните безопасную проверку',
		AJsuccess: 'Проверка прошла успешно',
		AJfailed: 'Проверка не удалась',
		hintTitle: 'Подсказка:',
		hintText: 'Если вы не получили код подтверждения, проверьте папку спама в своей почте и убедитесь, что адрес электронной почты введен верно. Также, добавьте mail@m.whistle3.com в белый список.',
		SignUp: 'Регистрация',
		SignUpTitle: 'Создать новую учетную запись',
		email: 'Введите адрес электронной почты для регистрации',
		email_code: 'Введите код подтверждения по электронной почте',
		getCode: 'Получить код',
		recomId: 'Введите код приглашения',
		pwd: 'Установите пароль',
		pwdIn: 'Повторите ввод пароля',
		loginBut: 'Зарегистрироваться',
		check: 'Я прочитал и согласен с',
		userAgree: 'Пользовательским соглашением',
		and: 'и',
		privacyPolicy: 'Политикой конфиденциальности',
		download: 'Перейти к загрузке',
		loginToast1: 'Пожалуйста, согласитесь с условиями',
		loginToast2: 'Пожалуйста, введите действительный адрес электронной почты',
		loginToast3: 'Пожалуйста, сначала введите адрес электронной почты',
		loginToast4: 'Пожалуйста, сначала введите код подтверждения',
		loginToast5: 'Пожалуйста, сначала введите пароль',
		loginToast6: 'Пароль должен содержать не менее 8 символов',
		loginToast7: 'Пожалуйста, сначала введите подтверждение пароля',
		loginToast8: 'Пароли не совпадают',
		loginToast9: 'Регистрация прошла успешно',
		loginToast10: '',
	},
	// ...zhCN,
	// ...zhLocale
}

export default ru
