const MPC = () => import("@/views/agreement/MPC.vue");
const about = () => import("@/views/agreement/about.vue");
const PrivacyPolicyZh = () => import("@/views/agreement/PrivacyPolicyZh.vue");
const PrivacyPolicyEn = () => import("@/views/agreement/PrivacyPolicyEn.vue");
const PrivacyPolicyJa = () => import("@/views/agreement/PrivacyPolicyJa.vue");
const PrivacyPolicyKo = () => import("@/views/agreement/PrivacyPolicyKo.vue");

const UserServiceAgreementZh = () => import("@/views/agreement/UserServiceAgreementZh.vue");
const UserServiceAgreementEn = () => import("@/views/agreement/UserServiceAgreementEn.vue");
const UserServiceAgreementJa = () => import("@/views/agreement/UserServiceAgreementJa.vue");
const UserServiceAgreementKo = () => import("@/views/agreement/UserServiceAgreementKo.vue");

const RechargeAgreementZh = () => import("@/views/agreement/RechargeAgreementZh.vue");
const RechargeAgreementEn = () => import("@/views/agreement/RechargeAgreementEn.vue");
const RechargeAgreementJa = () => import("@/views/agreement/RechargeAgreementJa.vue");
const RechargeAgreementKo = () => import("@/views/agreement/RechargeAgreementKo.vue");

const LiveAgreementZh = () => import("@/views/agreement/LiveAgreementZh.vue");
const LiveAgreementEn = () => import("@/views/agreement/LiveAgreementEn.vue");
const LiveAgreementJa = () => import("@/views/agreement/LiveAgreementJa.vue");
const LiveAgreementKo = () => import("@/views/agreement/LiveAgreementKo.vue");

export default [
	{
		path: '/MPC', // 直播服务协议 ko
		name: 'MPC',
		component: MPC,
		meta: {
			title: 'MPC服务条款',
			keepAlive: false
		}
	},
	{
		path: '/about', // 关于我们
		name: 'about',
		component: about,
		meta: {
			title: '关于我们',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyZh', // 隐私政策 zh
		name: 'PrivacyPolicyZh',
		component: PrivacyPolicyZh,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyEn', // 隐私政策 en
		name: 'PrivacyPolicyEn',
		component: PrivacyPolicyEn,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyJa', // 隐私政策 ja
		name: 'PrivacyPolicyJa',
		component: PrivacyPolicyJa,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/PrivacyPolicyKo', // 隐私政策 ko
		name: 'PrivacyPolicyKo',
		component: PrivacyPolicyKo,
		meta: {
			title: '隐私政策',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementZh', // 用户服务协议 zh
		name: 'UserServiceAgreementZh',
		component: UserServiceAgreementZh,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementEn', // 用户服务协议 en
		name: 'UserServiceAgreementEn',
		component: UserServiceAgreementEn,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementJa', // 用户服务协议 ja
		name: 'UserServiceAgreementJa',
		component: UserServiceAgreementJa,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/UserServiceAgreementKo', // 用户服务协议 ko
		name: 'UserServiceAgreementKo',
		component: UserServiceAgreementKo,
		meta: {
			title: '用户服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementZh', // 充值服务协议 zh
		name: 'RechargeAgreementZh',
		component: RechargeAgreementZh,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementEn', // 充值服务协议 en
		name: 'RechargeAgreementEn',
		component: RechargeAgreementEn,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementJa', // 充值服务协议 ja
		name: 'RechargeAgreementJa',
		component: RechargeAgreementJa,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/RechargeAgreementKo', // 充值服务协议 ko
		name: 'RechargeAgreementKo',
		component: RechargeAgreementKo,
		meta: {
			title: '充值服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementZh', // 直播服务协议 zh
		name: 'LiveAgreementZh',
		component: LiveAgreementZh,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementEn', // 直播服务协议 en
		name: 'LiveAgreementEn',
		component: LiveAgreementEn,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementJa', // 直播服务协议 ja
		name: 'LiveAgreementJa',
		component: LiveAgreementJa,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
	{
		path: '/LiveAgreementKo', // 直播服务协议 ko
		name: 'LiveAgreementKo',
		component: LiveAgreementKo,
		meta: {
			title: '直播服务协议',
			keepAlive: false
		}
	},
]