const download = () => import("@/views/download/download.vue");

export default [
	{
		path: '/download', // 下载
		name: 'download',
		component: download,
		meta: {
			title: '下载',
			keepAlive: false
		}
	},
]