const information = () => import("@/views/information/information.vue");
const informationDetails = () => import("@/views/information/informationDetails.vue");

export default [
	{
		path: '/information', // 资讯
		name: 'information',
		component: information,
		meta: {
			title: '资讯',
			keepAlive: false
		}
	},
	{
		path: '/informationDetails', // 资讯详情
		name: 'informationDetails',
		component: informationDetails,
		meta: {
			title: '资讯详情',
			keepAlive: false
		}
	},
]