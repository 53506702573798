const controlConsole = () => import("@/views/controlConsole/controlConsole.vue");

export default [
	{
		path: '/controlConsole', // 控制台
		name: 'controlConsole',
		component: controlConsole,
		meta: {
			title: '控制台',
			keepAlive: false
		}
	},
]