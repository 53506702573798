import enUS from 'vant/lib/locale/lang/en-US'
import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
	lang: {
		// login页面
		language: 'language',
		language1: '简体中文',
		language2: 'English',
		language3: '日本語',
		language4: '한국어',
		language5: 'Русский',
		language6: 'Tiếng Việt',
		cancel: 'Cancel',
		AJtitle: 'Complete verification',
		AJsuccess: 'Verification successful',
		AJfailed: 'verification failed',
		hintTitle: 'tips:',
		hintText: 'If you do not receive the verification code, please check your email spam and make sure the email address is correct. Also, please add mail@m.whistle3.com to the whitelist.',
		SignUp: 'Sign Up',
		SignUpTitle: 'Create your new account',
		email: 'Please enter email address',
		email_code: 'verification code',
		getCode: 'Get verification code',
		recomId: 'Enter invitation code',
		pwd: 'Password',
		pwdIn: 'Confirm Password',
		loginBut: 'Sign up',
		check: 'By signing up you’ve agree to',
		userAgree: '《User Agreement》',
		and: 'and',
		privacyPolicy: 'Our Terms of Use And Privacy Notice',
		download: 'To download',
		loginToast1: 'Please select Consent agreement',
		loginToast2: 'Please enter a valid email address',
		loginToast3: 'Please enter your email first',
		loginToast4: 'Please enter the verification code first',
		loginToast5: 'Please enter your password first',
		loginToast6: 'The password must be at least 8 characters long',
		loginToast7: 'Please confirm your password first',
		loginToast8: 'The two passwords are different',
		loginToast9: 'Registered successfully',
		loginToast10: '',
	},
	...enUS,
	...enLocale
}

export default en
